exports.components = {
  "component---src-components-blog-page-index-tsx": () => import("./../../../src/components/BlogPage/index.tsx" /* webpackChunkName: "component---src-components-blog-page-index-tsx" */),
  "component---src-components-blogs-list-index-tsx": () => import("./../../../src/components/BlogsList/index.tsx" /* webpackChunkName: "component---src-components-blogs-list-index-tsx" */),
  "component---src-components-certification-page-index-tsx": () => import("./../../../src/components/CertificationPage/index.tsx" /* webpackChunkName: "component---src-components-certification-page-index-tsx" */),
  "component---src-components-sanity-page-index-js": () => import("./../../../src/components/SanityPage/index.js" /* webpackChunkName: "component---src-components-sanity-page-index-js" */),
  "component---src-components-vendor-index-tsx": () => import("./../../../src/components/Vendor/index.tsx" /* webpackChunkName: "component---src-components-vendor-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cbt-landing-js": () => import("./../../../src/pages/cbt-landing.js" /* webpackChunkName: "component---src-pages-cbt-landing-js" */),
  "component---src-pages-certifications-tsx": () => import("./../../../src/pages/certifications.tsx" /* webpackChunkName: "component---src-pages-certifications-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-discounted-exam-vouchers-tsx": () => import("./../../../src/pages/discounted-exam-vouchers.tsx" /* webpackChunkName: "component---src-pages-discounted-exam-vouchers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rack-rentals-tsx": () => import("./../../../src/pages/rack-rentals.tsx" /* webpackChunkName: "component---src-pages-rack-rentals-tsx" */),
  "component---src-pages-refund-policy-tsx": () => import("./../../../src/pages/refund-policy.tsx" /* webpackChunkName: "component---src-pages-refund-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-wgu-landing-page-jsx": () => import("./../../../src/pages/wgu-landing-page.jsx" /* webpackChunkName: "component---src-pages-wgu-landing-page-jsx" */)
}

